import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ILogger } from '../../observability/logger';
import { LOGGER_FACTORY, LoggerFactory } from '../../observability/provider';

import { balanceResponse } from './balance-response';
import { defaultResponse } from './default-response';

@Injectable({
  providedIn: 'root',
})
export class BalanceService {
  endPointBase: string = '/Balance';

  response: balanceResponse = defaultResponse;

  public get complete(): boolean {
    return this._complete;
  }

  public set complete(value: boolean) {
    this.logger.debug('Property Changed', {
      name: 'complete',
      old: this._complete,
      new: value,
    });

    this._complete = value;
  }

  private _complete: boolean = false;
  private logger: ILogger;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  responseSubject = new BehaviorSubject<balanceResponse | any>(null);
  response$ = this.responseSubject.asObservable();

  constructor(
    private http: HttpClient,
    @Inject(LOGGER_FACTORY) loggerFactory: LoggerFactory
  ) {
    this.logger = loggerFactory('BalanceService');
  }

  fetchBalance() {
    this.complete = false;
    this.http
      .post<balanceResponse>(
        environment.apiUrl + this.endPointBase,
        {},
        {
          observe: 'response',
        }
      )
      .subscribe({
        next: (res: HttpResponse<balanceResponse>) => {
          if (res.status === 200 && res.body) {
            this.response = res.body;
            this.responseSubject.next(res.body);
            if (res && res.body) {
              this.complete = true;
            }
          }
        },
      });
  }

  getBalance() {
    return this.response; // Return data stored in the service
  }
}
