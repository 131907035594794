export const defaultResponse = {
  balance: 0,
  success: false,
  returnCode: 0,
  openCharges: [],
  openChargesByPatients: [
    {
      patientName: 'string',
      groupedCharges: [
        {
          canDisplayChargeLevelInsuranceAmounts: true,
          encounterHeader: 'string',
          charges: [
            {
              chargeId: 0,
              description: 'string',
              baseFee: 0,
              totalInsPayments: 0,
              guarantorTotalPayments: 0,
              adjustments: 0,
              balance: 0,
              denied: true,
              atAgency: true,
              atAgencyDate: '2024-06-03T20:30:20.354Z',
              locationName: 'string',
              officeVisitNo: 'string',
              locationGroupCode: 'string',
              patientEncounterNumber: 'string',
              hasChargeLevelRemitInformation: true,
              status: 0,
              filingProfileId: 'string',
              activityDate: '2024-06-03T20:30:20.354Z',
              patientName: 'string',
              patientId: 0,
              draftTransactionsBalance: 0,
              pendingTransactionsBalance: 0,
              tapeChargeTransactionsBalance: 0,
            },
          ],
          activityDate: '2024-06-03T20:30:20.354Z',
          totalInsPayments: 0,
          guarantorTotalPayments: 0,
          adjustments: 0,
          balance: 0,
          draftTransactionsBalance: 0,
          pendingTransactionsBalance: 0,
          tapeChargeTransactionsBalance: 0,
        },
      ],
      startActivityDate: '2024-06-03T20:30:20.354Z',
      endActivityDate: '2024-06-03T20:30:20.354Z',
    },
  ],
  pendingChargesByPatients: [
    {
      patientName: 'string',
      charges: [
        {
          chargeId: 0,
          description: 'string',
          baseFee: 0,
          totalInsPayments: 0,
          guarantorTotalPayments: 0,
          adjustments: 0,
          balance: 0,
          denied: true,
          atAgency: true,
          atAgencyDate: '2024-06-03T20:30:20.354Z',
          locationName: 'string',
          officeVisitNo: 'string',
          locationGroupCode: 'string',
          patientEncounterNumber: 'string',
          hasChargeLevelRemitInformation: true,
          status: 0,
          filingProfileId: 'string',
          activityDate: '2024-06-03T20:30:20.354Z',
          patientName: 'string',
          patientId: 0,
          draftTransactionsBalance: 0,
          pendingTransactionsBalance: 0,
          tapeChargeTransactionsBalance: 0,
        },
      ],
      startActivityDate: '2024-06-13T13:18:29.357Z',
      endActivityDate: '2024-06-13T13:18:29.357Z',
    },
  ],
  closedCharges: [
    {
      chargeId: 0,
      description: 'string',
      baseFee: 0,
      totalInsPayments: 0,
      guarantorTotalPayments: 0,
      adjustments: 0,
      balance: 0,
      denied: true,
      atAgency: true,
      atAgencyDate: '2024-06-03T20:30:20.354Z',
      locationName: 'string',
      officeVisitNo: 'string',
      locationGroupCode: 'string',
      patientEncounterNumber: 'string',
      hasChargeLevelRemitInformation: true,
      status: 0,
      filingProfileId: 'string',
      activityDate: '2024-06-03T20:30:20.354Z',
      patientName: 'string',
      patientId: 0,
      draftTransactionsBalance: 0,
      pendingTransactionsBalance: 0,
      tapeChargeTransactionsBalance: 0,
    },
  ],
};
